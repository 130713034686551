import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {ApplyDiscount, CampaignBar, PackageCategoryList, SelectedPackageContent, SelectPackage} from "./components";
import useAnalytics from "../../hooks/useAnalytics";
import {MarketSourceTypes, NoteMessage} from "../../components/Constants";
import {encodeToBase64AndUri, parseMarketRouteKey, parseMobileKeyToJson} from "../../utils";
import {url} from "../../routes/utility";
import {useSelector} from "react-redux";
import useMemberNote from "../../hooks/useMemberNote";
import { GetMentorInformation } from "../../services/Dashboard/DashboardService";
import useCurrentTimeStamp from "../../hooks/useCurrentTimeStamp";

const PackageCatalog = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const analytics = useAnalytics();
    const hourlyDiscountRender = useSelector(state => state.componentRenderStore.Market.HourlyDiscount);
    const { member } = useAuth();
    const { selectedPackageInfo } = useParams();
    const { isWithinWorkingHours } = useCurrentTimeStamp();
    const postMemberNote = useMemberNote();

    const firstVisit = useRef(true);
    const packageNotesRef = useRef([]);
    const isUnmounting = useRef(false);

    const isMobile = location.pathname.includes("/mobile_market_package_catalog");
    const memberInfo = sessionStorage.getItem("m") ? (parseMobileKeyToJson(sessionStorage.getItem("m"))) : member;
    const opportunityPackageCategoryId = 10;

    // Seçili kategorinin tutulduğu state (Genel İngilizce, İş İngilizcesi vb.)
    const [selectedCategory, setSelectedCategory] = useState(null);
    // Seçili paketin tutulduğu state
    const [selectedProduct, setSelectedProduct] = useState({});
    // İndirim bilgilerinin tutulduğu state
    const [discountInformation, setDiscountInformation] = useState({});
    // Path'ten gelen bir değer var ise, ilgili verilerin tutulduğu state
    const [pathInfo, setPathInfo] = useState(false);
    // Mentor telefon numarasının tutulduğu state
    const [mentorPhoneNumber, setMentorPhoneNumber] = useState("");
    // Seçili paketin loglarının tutulduğu state
    const [selectedProductLogs, setSelectedProductLogs] = useState([]);
    // Fiyat bilgilerinin tetiklenmesini sağlayan state
    const [renewProductList, setRenewProductList] = useState(0);

    // Satın al butonu işlemlerini yapıldığı fonksiyon.
    const handleBuyButton = () => {
        // Seçili paket bilgileri set sessionStorage'a atılıyor.
        const purchasingInformations = {
            source: MarketSourceTypes.SPEAKING_LESSON,
            selectedCategoryId: setSelectedCategory?.id,
            selectedProduct: selectedProduct,
            discountInformation: discountInformation
        };
        const encodedString = encodeToBase64AndUri(purchasingInformations);
        sessionStorage.setItem("p", encodedString);

        // Loglama işlemleri
        analytics.useAnalytics('Market', {
            screen_name: 'market_satin_al_clicked'
        });
        window.customDataLayer.push({ event: 'Market', screen_name: 'market_satin_al_clicked' });

        // Yönlendirme İşlemleri
        let redirectUrl = "market.checkout";

        if (isMobile) {
            redirectUrl = "mobile_market_checkout";
        }

        navigate(url(redirectUrl));
    }

    // Kullanıcı paket değiştirdikçe state hangi paketi değiştirdiğini tutan state update ediliyor.
    const handleChangeProduct = ({ tempSelectedProduct }) => {
        if (!firstVisit.current) {
            setSelectedProductLogs([...selectedProductLogs, tempSelectedProduct.title])
        }
    }

    // Sayfa çıkışında not atılmak için veriler ref üzerinde tutuluyor.
    // Ref'te tutmamızın sebebi, sayfa çıkışlarında sorun yaşanmasıdır.
    useEffect(() => {
        packageNotesRef.current = selectedProductLogs.map((item) => ({
            memberId: memberInfo?.MemberId,
            noteTitleId: 43,
            note: NoteMessage.MARKET_PACKAGE.replace("#package#", item),
            createUserId: memberInfo?.MemberId
        }));
    }, [selectedProductLogs]);

    // Sayfa çıkışında ilgili ref true'ya çekiliyor
    useEffect(() => {
        return () => {
            isUnmounting.current = true;
        };
    }, [location]);

    // Notlar servise gönderiliyor.
    useEffect(() => {
        return () => {
            if (isUnmounting.current) {
                postMemberNote(packageNotesRef.current);
            }
        };
    }, []);

    // Mobilden gönderilir ise path token bilgileri set ediliyor.
    // Web'den gönderilen bir path değeri var ise set ediliyor.
    useEffect(() => {
        if (isMobile) {
            const isHaveSelectProps = (memberInfo?.Day && memberInfo?.Minute && memberInfo?.Month);
            setPathInfo(pathInfo => isHaveSelectProps ? memberInfo : false);
        } else {
            const tempPathInfo = selectedPackageInfo ? parseMarketRouteKey(selectedPackageInfo) : false;
            setPathInfo(pathInfo => tempPathInfo);
        }
    }, []);

    useEffect(() => {
        if(memberInfo?.MemberId) {
            GetMentorInformation(memberInfo?.MemberId)
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        setMentorPhoneNumber(resultContent?.phoneNumber);
                    }
                })
                .catch(e => {})
        }
    }, [memberInfo?.MemberId])

    // Mobil için geri butonunun görünüp/görünmeme durumu belirleniyor.
    useEffect(() => {
        if (isMobile) {
            const page = {
                "second_page": true
            }
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(page))
        }
    }, [])

    return (
        <div className={classNames("", {
            "main-content-mobile": isMobile,
            "main-content": !isMobile
        })}>
            {/* Kategorilerin listelendiği component */}
            <PackageCategoryList
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                pathInfo={pathInfo}
                memberInfo={memberInfo}
            />

            {/* Paket seçiminin yapıldığı component */}
            <SelectPackage
                selectedCategory={selectedCategory}
                setSelectedProduct={setSelectedProduct}
                handleChangeProduct={handleChangeProduct}
                totalDiscountRate={discountInformation?.totalDiscount}
                renewProductList={renewProductList}
                memberInfo={memberInfo}
                pathInfo={pathInfo}
            />

            {(hourlyDiscountRender.visibility && opportunityPackageCategoryId !== selectedProduct?.categoryId) &&
                <CampaignBar 
                    mentorPhoneNumber={mentorPhoneNumber}
                    isWithinWorkingHours={isWithinWorkingHours}
                    setRenewProductList={setRenewProductList}
                />
            }

            {/* İndirim işlemlerinin gerçekleştiği component */}
            <ApplyDiscount
                selectedProduct={selectedProduct}
                setDiscountInformation={setDiscountInformation}
                memberInfo={memberInfo}
                mentorPhoneNumber={mentorPhoneNumber}
                isWithinWorkingHours={isWithinWorkingHours}
                setRenewProductList={setRenewProductList}
            />

            {/* Product yüklendikten sonra alt componentleri göster */}
            {Object.keys(selectedProduct).length > 0 && (
                <>
                    {/* Satın alma butonu */}
                    <div className="btn-outer-div">
                        <button
                            type="button"
                            className="button primary-button"
                            onClick={handleBuyButton}
                        >
                            {strings.market.buy_button}
                        </button>
                    </div>

                    {/* Seçili paket içeriklerinin gösterildiği component */}
                    <SelectedPackageContent
                        selectedProduct={selectedProduct}
                        useRef={firstVisit.current = false}
                    />
                </>
            )}

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default PackageCatalog;