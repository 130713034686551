import {useLocalization} from "../../../../hooks/useLocalization";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateRenderStatus} from "../../../../store/ComponentRenderSlice";
import {ComponentRenderGroups, ComponentRenderKeys} from "../../../../components/Constants";
import classNames from "classnames";
import Counter from "./Counter";

const CampaignBar = (props) => {

    const { 
        classnames = "",  
        mentorPhoneNumber = "", 
        isWithinWorkingHours = false,
        setRenewProductList
    } = props;

    const strings = useLocalization();
    const dispatch = useDispatch();
    const hourlyDiscountRender = useSelector(state => state.componentRenderStore.Market.HourlyDiscount);

    const handleTimerEnd = () => {
        dispatch(updateRenderStatus({
            group: ComponentRenderGroups.MARKET,
            key: ComponentRenderKeys.HOURLY_DISCOUNT,
            value: {
                visibility: false,
                endDate: null,
                campaignDuration: null,
                resetList: true
            }
        }));
        setRenewProductList(prevState => prevState + 1);
    };

    if (!hourlyDiscountRender?.endDate) return null;

    return (
        <div
            className={classNames("rounded-[10px] bg-gradient-to-r from-primary to-blue-600 p-4 mt-3 border border-onboarding-border shadow-lg", {
                [classnames] : [!!classnames]
            })}
        >
            <div className="flex justify-between items-center max-w-lg mx-auto">
                <div className="flex flex-col justify-center items-center gap-3 w-full">
                    <h3 className="font-bold text-white text-[18px] text-center">
                        {strings.market.campaign_bar.title}
                    </h3>
                    <Counter endDate={hourlyDiscountRender.endDate} onTimerEnd={handleTimerEnd} />
                    <p className="text-white text-sm md:text-base text-center max-w-md">
                        {strings.market.campaign_bar.description.replace("#hour#", hourlyDiscountRender.campaignDuration)}
                    </p>
                </div>
            </div>

            {(mentorPhoneNumber.length > 0 && isWithinWorkingHours) && 
                <a 
                    className="button ml-auto sm:mx-auto text-sm bg-white text-base-text h-[30px] leading-4 flex items-center justify-center w-[160px] sm:w-[180px] hover:text-secondary focus:text-secondary mt-3"
                    href={`tel:${mentorPhoneNumber}`}
                >
                    {strings.market.speakingLesson.auto_create_campaign.call_mentor}
                </a>
            }
        </div>
    );
};

export default CampaignBar;