import {checkNumber, formatCreditCardNumber, removeSpaces} from "../../../../utils";
import {Card_Number} from "../../../../assets/svg";
import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {BinCheck} from "../../../../services/Iyzico/IyzicoService";
import {
    AmericanExpress,
    Axess,
    BankKartKombo,
    Bonus,
    CardFinans,
    Garanti,
    Halkbank,
    IsBankasi,
    KuveytTurk,
    Mastercard,
    Maximum, MilesAndSmiles,
    Neo,
    Paracard,
    Paraf,
    QnbFinansbank, Shopfly,
    TlCard,
    Troy,
    Vakifbank,
    Visa,
    WorldCard,
    ZiraatBank
} from "../../../../assets/img";

const BinChecker = ({ changeCreditCardInfo, creditCardInfo, setSelectedBank, bankList, setIsOneShot }) => {

    const strings = useLocalization();
    // BIN check servisinin karşıladığı karakter sayısı default olarak tanımlanıyor.
    const binCheckCharacterLength = 8;

    // Servise bir defa gitmek için eski bin numarası ile yeni girilen bin numarasını karşılaştırmak amacıyla bu state tutuluyor
    const [tempBin, setTempBin] = useState(null);
    // Servisten gelen kredi kartı bilgilerinin tutulduğu state
    const [cardInfo, setCardInfo] = useState({});
    // Kart tipi logosunun tutulduğu state
    const [cardAssociationLogo, setCardAssociationLogo] = useState(null);
    // Kart ailesi logosunun tutulduğu state
    const [cardFamilyLogo, setCardFamilyLogo] = useState(null);

    const cardTypes = Object.freeze(
        {
            "DEBIT_CARD": "DEBIT_CARD",
            "CREDIT_CARD": "CREDIT_CARD"
        }
    )

    const cardAssociationLogos = [
        {
            cardAssociation: "MASTER_CARD",
            logo: Mastercard
        },
        {
            cardAssociation: "VISA",
            logo: Visa
        },
        {
            cardAssociation: "AMERICAN_EXPRESS",
            logo: AmericanExpress
        },
        {
            cardAssociation: "TROY",
            logo: Troy
        }
    ];

    const cardFamilyLogos = [
        {
            cardFamily: "Neo",
            logo: Neo
        },
        {
            cardFamily: "Axess",
            logo: Axess
        },
        {
            cardFamily: "Paracard",
            logo: Paracard
        },
        {
            cardFamily: "Bonus",
            logo: Bonus
        },
        {
            cardFamily: "Cardfinans DC",
            logo: CardFinans
        },
        {
            cardFamily: "QNB Finans Bank CC",
            logo: QnbFinansbank
        },
        {
            cardFamily: "Halkbank DC",
            logo: Halkbank
        },
        {
            cardFamily: "Paraf",
            logo: Paraf
        },
        {
            cardFamily: "Bankamatik",
            logo: IsBankasi
        },
        {
            cardFamily: "Maximum",
            logo: Maximum
        },
        {
            cardFamily: "Vakıfbank DC",
            logo: Vakifbank
        },
        {
            cardFamily: "World",
            logo: WorldCard
        },
        {
            cardFamily: "Tlcard",
            logo: TlCard
        },
        {
            cardFamily: "BankkartCombo",
            logo: BankKartKombo
        },
        {
            cardFamily: "BankkartCombo DC",
            logo: BankKartKombo
        },
        {
            cardFamily: "Ziraat Bankası CC",
            logo: ZiraatBank
        },
        {
            cardFamily: "Garanti CC",
            logo: Garanti
        },
        {
            cardFamily: "Shop&Fly",
            logo: Shopfly
        },
        {
            cardFamily: "Miles&Smiles",
            logo: MilesAndSmiles
        },
        {
            cardFamily: "Kuveyt Türk CC",
            logo: KuveytTurk
        },
        {
            cardFamily: "Kuveyt Türk DC",
            logo: KuveytTurk
        }
    ];

    const cardFamilyBanks = [
        {
            cardFamily: "Axess",
            bank: "Akbank"
        },
        {
            cardFamily: "Bonus",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "QNB Finans Bank CC",
            bank: "Finans Bank"
        },
        {
            cardFamily: "Maximum",
            bank: "İş Bankası"
        },
        // world kartlar kendine ait bankaya gideceği için
        // bu kısım yorum satırına alındı
        // {
        //     cardFamily: "World",
        //     bank: "Vakıfbank"
        // },
        {
            cardFamily: "BankkartCombo",
            bank: "Ziraat Bankası"
        },
        {
            cardFamily: "BankkartCombo DC",
            bank: "Ziraat Bankası"
        },
        {
            cardFamily: "Ziraat Bankası CC",
            bank: "Ziraat Bankası"
        },
        {
            cardFamily: "Paraf",
            bank: "Halkbank"
        },
        {
            cardFamily: "Advantage",
            bank: "Finans Bank"
        },
        {
            cardFamily: "Şeker Bank CC",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "TEB CC",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Garanti CC",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Shop&Fly",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Miles&Smiles",
            bank: "Garanti Bankası"
        },
        {
            cardFamily: "Kuveyt Türk CC",
            bank: "Kuveyt Türk"
        }
    ]

    // Kendi sistemimiz ile dış servis sisteminin bilgilerini eşleştirmek için kullanılan array
    const cardBankNames = [
        {
            providerBank: "Akbank",
            systemBank: "Akbank"
        },
        {
            providerBank: "Finansbank",
            systemBank: "Finans Bank"
        },
        {
            providerBank: "Garanti Bankası",
            systemBank: "Garanti Bankası"
        },
        {
            providerBank: "Halk Bankası",
            systemBank: "Halkbank"
        },
        {
            providerBank: "İş Bankası",
            systemBank: "İş Bankası"
        },
        {
            providerBank: "Vakıfbank",
            systemBank: "Vakıfbank"
        },
        {
            providerBank: "Yapı Kredi Bankası",
            systemBank: "Yapıkredi"
        },
        {
            providerBank: "Ziraat Bankası",
            systemBank: "Ziraat Bankası"
        },
        {
            providerBank: "Kuveyt Türk",
            systemBank: "Kuveyt Türk"
        }
    ]

    useEffect(() => {
        /*
            * Kart numarasındaki boşluklar siliniyor ve ilk 6 hanesi alınıyor
            * İlk 6 hanesinin alınmasının sebebi BIN check servisin ilk 6 hane ile çalışmasıdır
        */
        const binNumber = removeSpaces(creditCardInfo?.cardNumber).substring(0 ,8);

        if(binNumber.length >= binCheckCharacterLength && tempBin !== binNumber) {
            setTempBin(binNumber);

            BinCheck(binNumber.substring(0, 6))
                .then(result => {
                    if(result.status === 200) {
                        const resultContent = JSON.parse(result.content);
                        setCardInfo(resultContent);
                    }
                })
                .catch()
        } else if (binNumber.length <= 7) {
            setCardInfo({});
            setTempBin(null);
        }
    }, [creditCardInfo.cardNumber]);

    useEffect(() => {
        if(Object.keys(cardInfo).length > 0) {
            changeCreditCardInfo({ isForeignCard: false });

            // Kart tipi (mastercard, visa vs) logosu set ediliyor.
            const tempCardAssociationLogo = cardAssociationLogos.find(cl => cl.cardAssociation === cardInfo?.cardAssociation) || false;
            tempCardAssociationLogo
                ? setCardAssociationLogo(tempCardAssociationLogo?.logo)
                : setCardAssociationLogo(null);

            // Kart ailesi (bonus, maximum, neo vs) logosu set ediliyor.
            const tempCardFamilyLogo = cardFamilyLogos.find(cf => cf.cardFamily === cardInfo?.cardFamily) || false;
            tempCardFamilyLogo
                ? setCardFamilyLogo(tempCardFamilyLogo?.logo)
                : setCardFamilyLogo(null);

            // Eğer kart debit card ise (banka kartı) tek çekim çekilmesi için oneShot state'i true'ya set ediliyor
            if(cardInfo.cardType === cardTypes.DEBIT_CARD) {
                const tempBank = bankList.find(b => b.name === "İş Bankası");
                setSelectedBank(tempBank);
                setIsOneShot(true);
            } else if(cardInfo.cardType === cardTypes.CREDIT_CARD) {
                // Eğer kart credit card ise aşağıdaki işlemlerden devam ediliyor
                setIsOneShot(false);

                const checkCardFamily = cardFamilyBanks.find(cf => cf.cardFamily === cardInfo.cardFamily) || false;

                /*
                  * Eğer kart gruplarım(bonus, axess vs.) içerisinde, kullanıcının girdiği kart bulunursa,
                  * İlgili karta ait banka set ediliyor
                */
                if(checkCardFamily) {
                    const tempBank = bankList.find(b => b.name === checkCardFamily.bank);
                    setSelectedBank(tempBank);
                } else {
                    /*
                        * Eğer banka kart grubu bulunamadıysa sistemimizdeki banka adları ile karşılaştırma yapılıp,
                        * Banka bilgisi set ediliyor.
                    */
                    const tempBankName = cardBankNames.find(cb => cb.providerBank === cardInfo.bankName) || false;

                    if(tempBankName) {
                        const tempBank = bankList.find(b => b.name === tempBankName.systemBank);
                        setSelectedBank(tempBank);
                    } else {
                        // Eğer status "success" ise kart Türkiye kartı demektir.
                        if(cardInfo.status === "success") {
                            const tempBank = bankList.find(b => b.name === "İş Bankası");
                            setSelectedBank(tempBank);
                            setIsOneShot(true);
                        }
                    }
                }
            } else {
                // Eğer status "failure" ise kart yabancı ülke kartıdır ve Garanti Bankasından tek çekim olarak çekilmelidir.
                const tempBank = bankList.find(b => b.name === "Garanti Bankası");
                setSelectedBank(tempBank);
                setIsOneShot(true);
                changeCreditCardInfo({ isForeignCard: true });
            }
        } else {
            setCardAssociationLogo(null);
            setCardFamilyLogo(null);
            setSelectedBank({ id: -1, name: strings.market.checkout.choose_bank });
            changeCreditCardInfo({ isForeignCard: false });
        }
    }, [cardInfo])

    return (
        <div className="flex flex-col mt-2">
            <label className="block text-start" htmlFor="card-number">{strings.market.checkout.card_number}</label>
            <div className="w-full relative group">
                <input
                    id="card-number"
                    type="text"
                    className="market-input"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    placeholder="---- ---- ---- ----"
                    maxLength="19"
                    value={formatCreditCardNumber(creditCardInfo.cardNumber)}
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Sadece sayılar izin verilir
                    }}
                    onChange={(e) => changeCreditCardInfo({cardNumber: e.target.value})}
                />
                <div className="market-input-left">
                    <img src={Card_Number} width="24" height="24" alt="card-number"/>
                </div>

                {cardAssociationLogo &&
                    <img
                        className="absolute right-2 bottom-0 top-[12px]"
                        src={cardAssociationLogo}
                        width={35}
                        height={17.5}
                        alt="card-family-logo"
                    />
                }

                {cardFamilyLogo &&
                    <img
                        className="absolute right-[48px] bottom-0 top-[12px]"
                        src={cardFamilyLogo}
                        width={35}
                        height={17.5}
                        alt="card-association-logo"
                    />
                }
            </div>
        </div>
    );
}

export default BinChecker;