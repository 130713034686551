const addTimeZone = (tm, timeZone) => {
    let today = new Date();
    let timeArr = tm.split(":");
    let time = new Date(today.getFullYear(), today.getMonth(), today.getDay(), parseInt(timeArr[0]), parseInt(timeArr[1]));
    let returnTime = new Date(time.setMinutes(time.getMinutes() + parseInt(timeZone)));
    return ("0" + returnTime.getHours()).slice(-2) + ':' + ("0" + returnTime.getMinutes()).slice(-2);
}

export const getTodayForLessonDate = () => {
    const date = new Date();
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

export const parseDateForLesson = date => {
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

export const parseDateForLessonTurkishCulture = date => {
    return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor
export const ISOStringToDate = (date, timeZone) => {
    let tmpDate = new Date(date);

    if (date.includes("T")) {
        const dateArr = date.split("T");
        const dateTmpTime = dateArr[1].split(":");
        tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), dateTmpTime[0], dateTmpTime[1], dateTmpTime[2].split(".")[0]));
    }

    let returnDate = tmpDate.getFullYear() + "-" + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + "-" + ("0" + tmpDate.getDate()).slice(-2);
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes() + ":" + "00";
    return returnDate + "T" + addTimeZone(tmpTime, timeZone)
}

// 2023-11-8 formatında gelen tarihler 2023-11-08 olarak formatlanır
export const formatNoneZeroDateToZeroDateForDash = (date) => {
    const parts = date.split('-');

    if (parts.length === 3) {
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];

        if (month.length === 1) {
            parts[1] = '0' + month;
        }

        if (day.length === 1) {
            parts[2] = '0' + day;
        }

        return parts.join('-');
    } else {
        return 'Invalid Date';
    }
}

// 8.11.2023 formatında gelen tarihler 08.11.2023 olarak formatlanır
export const formatNoneZoreDateToZeroDateForDot = (date) => {
    let dateParts = date.split(".");

    let day = dateParts[0];
    let month = dateParts[1];

    if (day < 10 && day.length === 1) {
        day = "0" + day;
    }

    if (month < 10 && month.length === 1) {
        month = "0" + month;
    }

    date = day + "." + month + "." + dateParts[2];
    return date;
}

// 8.04.2024 formatında gelen tarihler 08.04.2024 olarak formatlanır
export const formatNoneZeroDateToZeroDateForDotForOnlyDays = (date) => {
    let dateParts = date.split(".");

    let day = dateParts[0];

    if (day < 10) {
        day = "0" + day;
    }

    date = day + "." + dateParts[1] + "." + dateParts[2];
    return date;
}

export const getyyyyMMddDate = (date) => {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);

    let formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
}

export const getyyyyMMddDate2 = (dateString) => {
    const dateSplit = dateString.split(".");

    return dateSplit[2] + "-" + dateSplit[1] + "-" + dateSplit["0"];
}

export const getDayDiff = (dateOne, dateTwo) => {
    let date1 = Date.UTC(dateOne.getFullYear(), dateOne.getMonth(), dateOne.getDate());
    let date2 = Date.UTC(dateTwo.getFullYear(), dateTwo.getMonth(), dateTwo.getDate());

    return parseInt((date1 - date2) / 86400000);
}

export const formatDateWithSlash = (date) => {
    const baseDate = new Date(date);

    const day = baseDate.getDate();
    const month = baseDate.getMonth() + 1;
    const year = baseDate.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`
}

// 2024-03-01T09:02:14.1266667 -> 1 Mart 2024 09:02
export const formatDateWithTime = (inputDate, language = 'tr', showHourText = false, showHour = true) => {
    const date = new Date(inputDate);

    const trMonthNames = [
        "Ocak", "Şubat", "Mart",
        "Nisan", "Mayıs", "Haziran",
        "Temmuz", "Ağustos", "Eylül",
        "Ekim", "Kasım", "Aralık"
    ];
    const trHourText = "saat";

    const enMonthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];
    const enHourText = "hour";

    const monthNames = (language === 'tr') ? trMonthNames : enMonthNames;
    const hourText = (language === 'tr') ? trHourText : enHourText;
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    // Yıl, Ay, Gün, Saat, Dakika değerlerini alır
    const year = date.getFullYear();
    const day = date.getDate();
    // Tek haneli ise başına "0" ekler
    const hours = ('0' + date.getHours()).slice(-2);
    // Tek haneli ise başına "0" ekler
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${day} ${monthName} ${year} ${showHourText ? hourText : ""} ${showHour ? `${hours}:${minutes}` : ""} `;
}

// 2024-03-01T09:02:14.1266667 -> Ocak 2022
export const formatDateWithMonthAndYear = (inputDate, language = 'tr') => {
    const date = new Date(inputDate);

    const trMonthNames = [
        "Ocak", "Şubat", "Mart",
        "Nisan", "Mayıs", "Haziran",
        "Temmuz", "Ağustos", "Eylül",
        "Ekim", "Kasım", "Aralık"
    ];

    const enMonthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];

    const monthNames = (language === 'tr') ? trMonthNames : enMonthNames;
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];

    const year = date.getFullYear();

    return `${monthName} ${year}`;
}

export const formatDateMarket = (date) => {
    return (date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2));
}

export const addDaysToDate = (date, days) => {
    const result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}

/**
 * '2023-11-20' formatındaki bir tarih string'ini '20231120' formatında bir integer'a dönüştürür.
 * 
 * @param {string} dateString - 'YYYY-MM-DD' formatında bir tarih string'i
 * @returns {number} YYYYMMDD formatında bir integer, eğer giriş geçersizse 0 döner
 */
export const formatDateToCompactInteger = (dateString) => {
    // Gelen string'i '-' karakterine göre bölelim
    const parts = dateString.split('-');
    
    // Eğer doğru formatta değilse, 0 döndürelim
    if (parts.length !== 3) {
        return null;
    }

    // Yıl, ay ve günü birleştirelim ve integer'a çevirelim
    const [year, month, day] = parts;
    return parseInt(`${year}${month}${day}`);
}